import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import './locales'
import { App } from 'realm-web'
import { setAppInstance } from 'store/appInstance/appSlice'
import { userLoggedOut } from 'store/auth/userSlice'

function MyApp() {
    const app = new App({ id: 'data-wallet-admin-hkzaa' })
    window.app = app
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setAppInstance(app))
    }, [])

    useEffect(() => {
        if (!app?.currentUser) {
            dispatch(userLoggedOut())
            app?.currentUser?.logOut()
            // window.location.reload()
        }
    }, [])

    return (
        <BrowserRouter history={history}>
            <Theme>
                <Layout />
            </Theme>
        </BrowserRouter>
    )
}

export default MyApp
