import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4NTM0ODI5NSwiaWF0IjoxNjg1MzQ4Mjk1fQ.4nDgLNEYufboir_UokmLkHu7OUnXDbs4sJV4Wqj5zYc',
        signedIn: true,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token =
                'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4NTM0ODI5NSwiaWF0IjoxNjg1MzQ4Mjk1fQ.4nDgLNEYufboir_UokmLkHu7OUnXDbs4sJV4Wqj5zYc'
        },
        onSignOutSuccess: (state) => {
            state.signedIn = true
            state.token = ''
        },
        setToken: (state, action) => {
            state.token =
                'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY4NTM0ODI5NSwiaWF0IjoxNjg1MzQ4Mjk1fQ.4nDgLNEYufboir_UokmLkHu7OUnXDbs4sJV4Wqj5zYc'
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken } =
    sessionSlice.actions

export default sessionSlice.reducer
